import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/privacy-policy-main.css';
import privacyPolicyImg from '../assets/privacy-policy/privacy-policy.svg'

const PrivacyPolicyMain = () => {
  return (
    <div className="privacy-policy">
      <img src={ privacyPolicyImg } width="10%" alt="" />
      <h1 className='heading-1'>Greenlands Grocer Privacy Policy</h1>
      
      <section>
        <h2 className='heading-1'>Introduction</h2>
        <p  className='heading-2'>
          Greenlands Grocer ("Greenlands Grocer," "we," "our," or "us") is committed to protecting your privacy. This <Link to='/privacy-policy'> Privacy Policy </Link> explains how we collect, use, disclose, and safeguard your information when you visit our website (<a href="https://www.greenlandsgrocer.com.au">https://www.greenlandsgrocer.com.au</a>), use our mobile application, or interact with our services. Please read this policy carefully to understand our views and practices regarding your personal information and how we will treat it.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>1. Information We Collect</h2>
        <ul className='heading-2'>
          <li><strong>Personal Information:</strong> Name, address, email address, phone number, date of birth, and other identifiers by which you may be contacted or identified.</li>
          <li><strong>Employment Information:</strong> Details such as job title, department, work history, performance evaluations, and payroll information.</li>
          <li><strong>Financial Information:</strong> Bank account numbers, payment details, and other financial data necessary for payroll processing.</li>
          <li><strong>Technical Data:</strong> IP address, login data, browser type and version, time zone setting, browser plug-in types, and other technology on the devices you use to access our website or application.</li>
          <li><strong>Usage Data:</strong> Information about how you use our website, products, and services, including access times, pages viewed, and actions taken.</li>
          <li><strong>Location Data:</strong> If you use our delivery services or application, we may collect real-time location data.</li>
        </ul>
      </section><br></br>

      <section>
        <h2 className='heading-1'>2. How We Collect Information</h2>
        <ul className='heading-2'>
          <li><strong>Direct Interactions:</strong> When you fill out forms, apply for a job, subscribe to our services, enter information on our website, or communicate with us by phone, email, or otherwise.</li>
          <li><strong>Automated Technologies:</strong> As you interact with our website, we may collect technical data about your equipment, browsing actions, and patterns through cookies, server logs, and other similar technologies.</li>
          <li><strong>Third Parties:</strong> We may receive <Link to='/data-collection'> personal data </Link> about you from various third parties, including analytics providers, advertising networks, search information providers, and others.</li>
        </ul>
      </section><br></br>

      <section>
        <h2 className='heading-1'>3. How We Use Your Information</h2>
        <ul  className='heading-2'>
          <li><strong>To Provide Our Services:</strong> Including managing your account, processing transactions, and delivering products.</li>
          <li><strong>To Improve Our Services:</strong> By analyzing your interactions with our website and application to enhance user experience and functionality.</li>
          <li><strong>To Communicate With You:</strong> About your account, orders, or employment, as well as to provide updates, newsletters, and other relevant information.</li>
          <li><strong>To Comply With Legal Obligations:</strong> Including record-keeping, tax compliance, and responding to lawful requests from public authorities.</li>
          <li><strong>For Employment Purposes:</strong> Managing payroll, assessing performance, and fulfilling our obligations as an employer.</li>
          <li><strong>For Security and Fraud Prevention:</strong> Protecting our business, website, and users from fraud and other illegal activities.</li>
        </ul>
      </section><br></br>

      <section>
        <h2 className='heading-1'>4. How We Share Your Information</h2>
        <ul  className='heading-2'>
          <li><strong>Service Providers:</strong> Companies that provide services on our behalf, such as payment processing, IT services, and analytics.</li>
          <li><strong>Affiliates and Subsidiaries:</strong> For business operations and improvements.</li>
          <li><strong>Legal Authorities:</strong> If required by law or to protect our rights and property.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of our assets.</li>
        </ul>
      </section><br></br>

      <section>
        <h2 className='heading-1'>5. Cookies and Tracking Technologies</h2>
        <p  className='heading-2'>
          Our website uses cookies and similar tracking technologies to enhance user experience. Cookies are small files placed on your device that help us understand how our website is used. You can control the use of cookies through your browser settings.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>6. Data Security</h2>
        <p  className='heading-2'>
          We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no internet-based service can be 100% secure, and we cannot guarantee the absolute security of your data.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>7. Data Retention</h2>
        <p  className='heading-2'>
          We retain your personal information only for as long as necessary to fulfill the purposes we collected it for, including legal, accounting, or reporting requirements.
        </p>
      </section>
      <br></br>
      <section>
        <h2 className='heading-1'>8. Your Rights</h2>
        <ul  className='heading-2'>
          <li><strong>Access Your Data:</strong> Request a copy of the personal data we hold about you.</li>
          <li><strong>Correct Your Data:</strong> Request corrections to any inaccurate or incomplete data.</li>
          <li><strong>Delete Your Data:</strong> Request deletion of your personal data, subject to certain legal obligations.</li>
          <li><strong>Object to Processing:</strong> Object to the processing of your personal data where we rely on legitimate interests.</li>
          <li><strong>Data Portability:</strong> Request the transfer of your personal data to another party.</li>
        </ul>
        <p>
          To exercise these rights, please contact us at <a href="mailto:sales@greenlandsfruit.com.au">sales@greenlandsfruit.com.au</a>.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>9. International Transfers</h2>
        <p  className='heading-2'>
          Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>10. Links to Other Websites</h2>
        <p className='heading-2'>
          Our website may contain links to other websites that are not operated by us. We are not responsible for the content, privacy policies, or practices of third-party websites.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>11. Changes to This Privacy Policy</h2>
        <p  className='heading-2'>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section><br></br>

      <section>
        <h2 className='heading-1'>12. Contact Us</h2>
        <p  className='heading-2'>
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p  className='heading-2'>
          Address: Greenlands Grocer <br />
          264 Liverpool Road, <br />
          Kilsyth South VIC 3137, <br />
          Australia.
        </p>
        <p  className='heading-2'>
          Email: <a href="mailto:sales@greenlandsfruit.com.au">sales@greenlandsfruit.com.au</a> <br />
          Phone: (03) 9728 6989
        </p>
      </section><br></br>
    </div>
  );
};
export default PrivacyPolicyMain;
