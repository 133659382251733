import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

//  Share authentication state and funtions through application.
const AuthContext = createContext();
const API_URL = "https://greenland-backend.azurewebsites.net/api/v1.0";

const AuthProvider = ({ children }) => {
  // save token here
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //eslint-disable-next-line
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole") || "");
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  // Login Function

  // Set local storage token
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
    }

     //set local storage userRole
     if (userRole) {
      localStorage.setItem("userRole", userRole);
    } else {
      localStorage.removeItem("userRole");
      
    }


  }, [token, userRole]);

  // Memorize the token ###
  // const contextValue = useMemo(
  //   () => ({
  //     token,
  //     userRole,
  //     isAuthenticated,
  //     login,
  //   }),
  //   [token, isAuthenticated, login, userRole]
  // );

  const contextValue = useMemo(() => {
    const login = async (contactno, password) => {
      try {
        const response = await axios.post(
          `${API_URL}/login/validation`,
          { contactno, password },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0L1RlY2hBZHZhbnRhZ2UvRGVsaXZlcnlNYW5hZ2VtZW50U3lzdGVtL0dyZWVubGFuZC1CYWNrZW5kLyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3QvVGVjaEFkdmFudGFnZS9EZWxpdmVyeU1hbmFnZW1lbnRTeXN0ZW0vR3JlZW5sYW5kLUJhY2tlbmQvIiwiaWF0IjoxNzIwOTA5NDc0LCJuYmYiOjE3MjA5MDk0NzQsImV4cCI6MTcyMDkxMzA3NCwiZGF0YSI6WyJUZWNoQWR2YW50YWdlUHJpbWUiLCJBbHBoYVRlc3ROZXciXX0.hwwZkz0UdXJ28LRmWnmwOsChdq_xeYYJfbuC3XrhphU",
            },
          }
        );
        // Setup values
        const accessToken = response.data.JWT_Token;
        const loginStatus = response.data.success;
        // Setting user id in local
        if (response.data.UserType === "Driver") {
          if (response.data.driverInfo) {
            localStorage.setItem("userID", response.data.driverInfo.DriverID);
            localStorage.setItem("userRole", response.data.UserType);
          }
        } else {
          localStorage.setItem("userID", response.data.UserID);
        }
        

        setUserRole(response.data.UserType);

        if (loginStatus === false) {
          alert("Please Check Your Credentails");
        }

        if (accessToken != null) {
          console.log("Login successful");
          setToken(accessToken);

          localStorage.setItem("contactNo", contactno);
          setIsAuthenticated(true);
          return response.data;
        }
      } catch (error) {
        console.error("Login failed:", error);
        setIsAuthenticated(false);
        console.error("login failed in initially");

        throw error;
      }
    };
    return {
      token,
      userRole,
      isAuthenticated,
      login,
    };
  },[userRole, token, isAuthenticated]);

  //   Provide authentication context to children
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
