// src/comingSoon.js
import React from "react";
import "../styles/coming_soon.css";
import Sidepanel from "../components/administrator/common/sidepanel";
import BottomPanel from "../components/administrator/common/bottomPanel";
const ComingSoon = () => {
  return (
    <>
      <div className="coming-soon__container">
        <Sidepanel />
        <div className="coming-soon__content">
          <h1>Coming Soon</h1>
          <p>We are working hard to bring you this feature. Stay tuned!</p>
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default ComingSoon;
