import React, { useEffect, useRef } from "react";
// components
import BottomPanel from "../common/bottomPanel";
// Icons
import { CiLocationArrow1 } from "react-icons/ci";
import { MdAdd } from "react-icons/md";
import { useParams } from "react-router-dom";
import { getDriverMessages, postMessage } from "../../../api/apiService";
import { useState } from "react";


const IndividualMessaging = () => {
  const { id } = useParams();
  const containerRef = useRef(null);
  const endOfMessageRef = useRef(null);
  const [driverMessages, setDriverMessages] = useState([]);
  // const userId = localStorage.getItem("userID");
  // const driverId = id;
  const [message, setMessage] = useState("");

  const getMessages = async () => {
    try {
      const response = await getDriverMessages(id);
      if (response.data.success === true) {
        setDriverMessages(response.data.ChatList);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getMessages();
  });

  // Send Message
  const sendMessage = (userId, driverId, message, images) => {
    console.log("called to send message")
    try {
      const response = postMessage(userId, driverId, message, images);
      console.log(response);

    } catch (err) {
      console.log(err);
    }
  };
  console.log(message)

  return (
    <section className="">
      <div>
        <div className="administrator__messaging__container__mob">
          <h1 className="title">CONTACT ADMINISTRATOR</h1>
          <div ref={containerRef} className="mobile-msg-container">
            {driverMessages
              .slice()
              .reverse()
              .map((message) =>
                message && message.ChatSender && message.Message ? (
                  <div
                    key={message.ChatID}
                    className={`${
                      message.ChatSender === "DRIVER"
                        ? "reciever__mob"
                        : "sender__mob"
                    }`}
                  >
                    <div>
                      <p className="heading-3">{message.Message}</p>
                    </div>
                  </div>
                ) : null
              )}
          </div>
          <div ref={endOfMessageRef} />
        </div>
        <div className="message__writter__container__mob">
          <button className="m__wm__button__send">
            <MdAdd size={30} />
          </button>
          <input
            className="message__writter__mob text"
            aria-autocomplete="inline"
            autoCorrect="on"
            spellCheck="true"
            contentEditable="true"
            placeholder="Type Your Message Here"
            onChange={(e) => setMessage(e.target.value)}
          />

          <button 
          className="m__wm__button__send"
          onClick={sendMessage}
          >
            <CiLocationArrow1 size={30} />
          </button>
        </div>
      </div>
      <BottomPanel />
    </section>
  );
};

export default IndividualMessaging;
