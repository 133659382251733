import React from "react";
import Sidepanel from "../../components/administrator/common/sidepanel";
import DailyProgress from "../../components/administrator/dashboard/dailyProgress";
import SelectDrop from '../../components/administrator/dashboard/selectdrop';

// CSS Styles
import "../../styles/administrator.css"
import BottomPanel from '../../components/administrator/common/bottomPanel'

const Dashboard = () => {
  return (
    <>
      <div className="admin__main__container">
        <Sidepanel />
        <div className="admin__drop__container">
          <h1 className="title">DASHBOARD</h1>
          <DailyProgress className='test-amc' />
          <SelectDrop className='test-amc'/>
        </div>
        <BottomPanel/>
      </div>
    </>
  );
};

export default Dashboard;
