import React from "react";
import Sidepanel from "../../components/administrator/common/sidepanel";
import BottomPanel from "../../components/administrator/common/bottomPanel";
import Map from "../../components/administrator/driverTracking/map";
import { anonymousLogin, onAuthChange, setupCollectionListener } from "../../services/firebaseService";
import { useState, useEffect } from "react";
const DriverTracking = () => {
  // const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const position = [48.4284, -123.3656]; // Default position [latitude, longitude]
  useEffect(() => {
    // Monitor auth state changes
    const unsubscribe = onAuthChange((user) => {
      if (user) {
        // setUser(user);
              // Set up real-time listener for the collection
      setupCollectionListener('tracking', (data) => { // Replace 'your-collection-name' with your actual collection name
        formatFirebaseResponse(data);
      });
    
      } else {
        // If no user is logged in, initiate anonymous login
        anonymousLogin()
          // .then((loggedInUser) => setUser(loggedInUser))
          .catch((error) =>
            console.error("Failed to login anonymously:", error)
          );
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // const handleFetchData = async () => {
  //   try {
  //     const data = await fetchData("tracking");
  //     if(data){
  //       formatFirebaseResponse(data);
  //     }
      
  //   } catch (error) {
  //     console.error("Failed to fetch data:", error);
  //   }
  // };
  const formatFirebaseResponse =(data) => {
    try {
        let datatest = [];
        data.forEach(e=>{
            const position = [parseFloat(e.lat),parseFloat(e.lng)];
            let obj = {position:position,popup:`${e.name} : ${e.contact}`}
            datatest.push(obj);
        });
        setData(datatest);
      
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  return (
    <>
      <div className="driver__management__container">
        <Sidepanel />
        <div className="driver__management__content">
          <h1 className="title">Driver Locations</h1>
          <Map position={position} markers={data} />
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default DriverTracking;
