import React from "react";
import "../../../styles/common.css";
// Hooks
import { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";

// React Icons
import { MdSpaceDashboard } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { IoIosChatboxes } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { TbHelpSquareFilled } from "react-icons/tb";
import { DRIVERROUTES } from "../../../routes";

const Sidepanel = () => {
  const [currentRoute, setCurrentRoute] = useState("")
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)

  useEffect (()=>{
    setCurrentRoute(location.pathname)

  },[location])
  return (
    <section>
      <div className="side__panel__container">
        <h1 className="title">Greenlands</h1>
        <div className="side__panel__menu">
          <h5 className="heading-2">Menu</h5>
          <button
            onClick={() => navigate(DRIVERROUTES.DASHBOARD)}
            className={`side__panel__item ${
              currentRoute === DRIVERROUTES.DASHBOARD ? "active__side__panel__item" : ""
            }`}
          >
            <MdSpaceDashboard />
            <p className="heading-3">Dashboard</p>
          </button>
          <button
            onClick={() => navigate(DRIVERROUTES.DRIVERHISTORY)}
            className={`side__panel__item ${
              currentRoute === DRIVERROUTES.DRIVERHISTORY ? "active__side__panel__item" : ""
            }`}
          >
            <FaHistory />
            <p className="heading-3">Driver&nbsp;History</p>
          </button>
          <button
            onClick={() => navigate(DRIVERROUTES.MESSAGING)}
            className={`side__panel__item ${
              currentRoute === DRIVERROUTES.MESSAGING ? "active__side__panel__item" : ""
            }`}
          >
            <IoIosChatboxes />
            <p className="heading-3">Contact</p>
          </button>
        </div>
        <div className="side__panel__others">
          <h5 className="heading-2">Others</h5>
          <button
            onClick={() => navigate(DRIVERROUTES.SETTINGS)}
            className={`side__panel__item ${
              currentRoute === DRIVERROUTES.SETTINGS ? "active__side__panel__item" : ""
            }`}
          >
            <IoMdSettings />
            <p className="heading-3">Settings</p>
          </button>
          <button
            onClick={() => navigate(DRIVERROUTES.HELP)}
            className={`side__panel__item ${
              currentRoute === DRIVERROUTES.HELP ? "active__side__panel__item" : ""
            }`}
          >
            <TbHelpSquareFilled />
            <p className="heading-3">Help</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Sidepanel;
