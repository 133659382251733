/* Need to find solution for make button clicked*/

import React from "react";
import "../../../styles/common.css";
// Hooks
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// React Icons
import { MdSpaceDashboard } from "react-icons/md";
import { FaHistory, FaMapPin } from "react-icons/fa";
import { IoIosChatboxes } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { TbHelpSquareFilled } from "react-icons/tb";
import { ADMINROUTES } from "../../../routes";

const Sidepanel = () => {
  const [currentRoute, setCurrentRoute] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  return (
    <section>
      <div className="side__panel__container">
        <h1 className="title">Greenlands</h1>
        <div className="side__panel__menu">
          <h5 className="heading-2">Menu</h5>
          {/* Link to dashboard */}

          <button
            onClick={() => navigate(ADMINROUTES.DASHBOARD)}
            className={`side__panel__item ${
              currentRoute === ADMINROUTES.DASHBOARD
                ? "active__side__panel__item"
                : ""
            }`}
          >
            <MdSpaceDashboard />
            <p className="heading-3">Dashboard</p>
          </button>

          {/* Link to driver management */}

          <button
            onClick={() => navigate(ADMINROUTES.DRIVERMANAGEMENT)}
            className={`side__panel__item ${
              currentRoute.includes(ADMINROUTES.DRIVERMANAGEMENT)
                ? "active__side__panel__item"
                : ""
            }`}
          >
            <FaHistory />
            <p className="heading-3">Driver&nbsp;Management</p>
          </button>

          {/* Link to driver Tracking */}

          <button
            onClick={() => navigate(ADMINROUTES.DriverTracking)}
            className={`side__panel__item ${
              currentRoute.includes(ADMINROUTES.DriverTracking)
                ? "active__side__panel__item"
                : ""
            }`}
          >
            <FaMapPin />
            <p className="heading-3">Driver&nbsp;Tracking</p>
          </button>


          {/* Navigate to contact */}
          <button
            onClick={() => navigate(ADMINROUTES.MESSAGING)}
            className={`side__panel__item ${
              currentRoute.includes(ADMINROUTES.MESSAGING)
                ? "active__side__panel__item"
                : ""
            }`}
          >
            <IoIosChatboxes />
            <p className="heading-3">Contact</p>
          </button>
        </div>
        <div className="side__panel__others">
          <h5 className="heading-2">Others</h5>
          <button
            onClick={() => navigate(ADMINROUTES.SETTINGS)}
            className={`side__panel__item ${
              currentRoute.includes(ADMINROUTES.SETTINGS)
                ? "active__side__panel__item"
                : ""
            }`}
          >
            <IoMdSettings />
            <p className="heading-3">Settings</p>
          </button>
          <button
            onClick={() => navigate(ADMINROUTES.HELP)}
            className={`side__panel__item ${
              currentRoute.includes(ADMINROUTES.HELP)
                ? "active__side__panel__item"
                : ""
            }`}
          >
            <TbHelpSquareFilled />
            <p className="heading-3">Help</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Sidepanel;
