import React from "react";
import "../../../styles/driver/components/no-data.css";
import noData from "../../../assets/driver/no-data/Folder Empty.svg";

const NoData = () => {
  return (
    <>
      <div className="drops-main-container">
        <img src={noData} alt="No data available" />
        <p className="heading-3">No Drops Available!!!</p>
      </div>
    </>
  );
};

export default NoData;
