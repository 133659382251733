import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

// icons
import { MdSpaceDashboard } from "react-icons/md";
import { GrDeliver } from "react-icons/gr";
import { AiFillMessage } from "react-icons/ai";
import { IoSettingsSharp } from "react-icons/io5";

const BottomPanel = ({ location }) => {
  return (
    <>
      <div className="bottom__panel__container">
        <NavLink to="/driver">
          <button>
            <MdSpaceDashboard
              size={20}
              className={`bottom__panel__icon ${
                location === "dashboard" ? "bottom-panel-clicked" : ""
              }`}
            />
          </button>
        </NavLink>
        <NavLink to="/driver/history">
          <button>
            <GrDeliver
              size={20}
              className={`bottom__panel__icon ${
                location === "history" ? "bottom-panel-clicked" : ""
              }`}
            />
          </button>
        </NavLink>
        <Link to="/driver/contact">
          <button>
            <AiFillMessage
              size={20}
              className={`bottom__panel__icon ${
                location === "messages" ? "bottom-panel-clicked" : ""
              }`}
            />
          </button>
        </Link>
        <Link to="/driver/settings">
          <button>
            <IoSettingsSharp
              size={20}
              className={`bottom__panel__icon ${
                location === "settings" ? "bottom-panel-clicked" : ""
              }`}
            />
          </button>
        </Link>
      </div>
      <Outlet />
    </>
  );
};

export default BottomPanel;
