import React from "react";
import "../../../styles/terms-of-use.css";
import "../../../assets/terms-of-use/terms-of-use.svg";
import termsOfUseImg from '../../../assets/terms-of-use/terms-of-use.svg'

const TermsOfUse = () => {
  return (
    <>
      <div className="tou-main-container">
        <div className="tou-image-container">
          <img src={ termsOfUseImg } alt="" />
        </div>
        <div className="tou-title-container">
          <h2 className="title">Greenlands Grocer Delivery Driver Application Terms of Use</h2><br></br>
        </div>
        <div className="tou-description-container">
          <h3 className="heading-1">1. Introduction</h3><br></br>
          <p className="heading-2">
            1.1 Greenlands Grocer (Pvt) Ltd. ("Greenlands Grocer," "we," "our,"
            or "us") has developed a mobile application ("Driver App") aimed at
            improving the efficiency and safety of our delivery operations. The
            Driver App is used to monitor delivery progress and assist in
            managing deliveries.
          </p><br></br>
          <p className="heading-2">
            1.2 These Terms of Use govern your access to and use of the Driver
            App as a delivery driver ("Driver") for Greenlands Grocer. By using
            the Driver App, you agree to comply with these Terms.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">2. Acceptance of Terms</h3><br></br>
          <p className="heading-2">
            2.1 These Terms of Use, along with our Privacy Policy and Staff Data
            Collection Notice, collectively govern your use of the Driver App.
            By accessing and using the Driver App, you acknowledge that you have
            read, understood, and agree to be bound by these Terms.
          </p><br></br>
          <p className="heading-2">
            2.2 If you do not agree to these Terms, you must not access or use
            the Driver App.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">3. Changes to Terms</h3><br></br>
          <p className="heading-2">
            3.1 Greenlands Grocer reserves the right to update or modify these
            Terms at any time. We will notify you of any material changes
            through the Driver App or other communication channels. Your
            continued use of the Driver App after any changes constitutes
            acceptance of the updated Terms.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">4. Your Account</h3><br></br>
          <p className="heading-2">
            4.1 To use the Driver App, you must create an account by providing
            certain personal information, including your full name and contact
            details.
          </p><br></br>
          <p className="heading-2">
            4.2 You are responsible for maintaining the confidentiality of your
            account information and for all activities that occur under your
            account.
          </p><br></br>
          <p className="heading-2">
            4.3 If you suspect unauthorized use of your account, you must notify
            Greenlands Grocer immediately.
          </p><br></br>
          <p className="heading-2">
            4.4 Greenlands Grocer reserves the right to suspend or terminate
            your access to the Driver App at our sole discretion, without notice
            or liability.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">5. Application Use and Restrictions</h3><br></br>
          <p className="heading-2">
            5.1 Greenlands Grocer grants you a non-exclusive, non-transferable
            license to use the Driver App solely for your duties as a Driver.
          </p><br></br>
          <p className="heading-2">
            5.2 You must not:
            <ul className="heading-2">
              <li>
                Copy, modify, or distribute the Driver App without our prior
                written consent.
              </li>
              <li>
                Use the Driver App in a way that violates any applicable laws or
                regulations.
              </li>
              <li>
                Harvest or collect personal information of others through the
                Driver App.
              </li>
              <li>
                Engage in any activity that disrupts the operation of the Driver
                App.
              </li>
            </ul>
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">6. Privacy</h3><br></br>
          <p className="heading-2">
            6.1 The collection and use of personal information through the
            Driver App are governed by our Privacy Policy and Staff Data
            Collection Notice.
          </p><br></br>
          <p className="heading-2">
            6.2 The Driver App tracks your location and driving behavior to
            ensure efficient delivery operations and customer satisfaction. By
            using the Driver App, you consent to the collection, use, and
            processing of this data.
          </p><br></br>
          <p className="heading-2">
            6.3 Greenlands Grocer may share your location and driving data with
            third-party service providers who assist in operating the Driver
            App. These third parties may be located in other jurisdictions,
            including outside of Australia.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">7. Confidential Information</h3><br></br>
          <p className="heading-2">
            7.1 You may have access to confidential information, including
            customer data, through the Driver App. You must not disclose,
            reproduce, or use any confidential information for purposes other
            than fulfilling your duties as a Driver.
          </p><br></br>
          <p className="heading-2">
            7.2 Upon termination of your use of the Driver App, you must return
            or destroy all confidential information in your possession.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">8. Third-Party Services</h3><br></br>
          <p className="heading-2">
            8.1 The Driver App may link to third-party services, such as mapping
            tools. Greenlands Grocer is not responsible for the content or
            practices of these third-party services.
          </p><br></br>
          <p className="heading-2">
            8.2 Your use of third-party services is subject to their respective
            terms and conditions.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">9. Intellectual Property</h3><br></br>
          <p className="heading-2">
            9.1 All intellectual property rights in the Driver App and its
            content are owned by Greenlands Grocer or our licensors.
          </p><br></br>
          <p className="heading-2">
            9.2 You must not infringe upon these rights or use any content from
            the Driver App for unauthorized purposes.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">Limitation of Liability</h3><br></br>
          <p className="heading-2">
            10.1 To the maximum extent permitted by law, Greenlands Grocer is
            not liable for any damages arising from your use of the Driver App,
            including any loss of data or damage to your device.
          </p><br></br>
          <p className="heading-2">
            10.2 Greenlands Grocer’s total liability to you for any claims
            arising out of or related to the Driver App is limited to AUD $100.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">11. Indemnity</h3><br></br>
          <p className="heading-2">
            11.1 You agree to indemnify and hold harmless Greenlands Grocer from
            any claims, damages, or losses arising from your use of the Driver
            App or breach of these Terms.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">12. Dispute Resolution</h3><br></br>
          <p className="heading-2">
            12.1 Any disputes arising out of these Terms will be resolved
            through good faith negotiations between the parties. If the dispute
            cannot be resolved through negotiations, it may be submitted to
            mediation or arbitration as agreed by the parties.
          </p>
        </div><br></br>
        <div className="tou-description-container">
          <h3 className="heading-1">13. General Provisions</h3><br></br>
          <p className="heading-2">
            13.1 If any provision of these Terms is found to be invalid or
            unenforceable, the remaining provisions will continue to be valid
            and enforceable.
          </p><br></br>
          <p className="heading-2">
            13.2 These Terms are governed by the laws of Australia, and you
            agree to submit to the non-exclusive jurisdiction of its courts.
          </p>
        </div><br></br>
      </div>
    </>
  );
};

export default TermsOfUse;