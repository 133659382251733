import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import loginVid from "../assets/login/login.mp4";
import { useAuth } from "../provider/authProvider";
import logo from "../assets/login/logo.png";
import poweredby from "../assets/login/poweredby.jpg";
import { toast, ToastContainer, Bounce } from "react-toastify";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [contactno, setContactno] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // handling the submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const responseData = await login(contactno, password);
      console.log(responseData);
      if (responseData.UserType === "Admin") {
        navigate("/administrator");
      } else if (responseData.UserType === "Driver") {
        navigate("/Driver");
      }
    } catch (error) {
      setError(error.message);
      toast.error("Please Check Your Credentials", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  localStorage.setItem("password", password);
  console.error(error);

  return (
    <>
      <div className="login__main__container">
        <div className="login__form__container">
          <img src={logo} alt="logo" />
          {/* <h1 className="title">Greenlands</h1> */}
          <h2 className="heading-2">Login into your Account</h2>
          {/* Login form */}
          <form action="POST" className="login__form" onSubmit={handleSubmit}>
            <div className="l__f__email">
              <h4 className="heading-3">Phone Number</h4>
              <input
                type="phone"
                id="email"
                name="email"
                className="input"
                onChange={(e) => setContactno(e.target.value)}
              />
            </div>
            <div className="l__f__password">
              <h4 className="heading-3">Password</h4>
              <input
                type="password"
                id="password"
                name="password"
                className="input"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <input type="submit" value={"Login"} className="btn-login" />

            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={poweredby} alt="powered by" style={{ width: "40px" }} />

              <p style={{ marginLeft: "3px", textDecoration:"none", fontSize: "12px" }}>
                Powered by{" "}
                <a
                  href="https://kytechsolutions.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KY Tech Solutions{" "}
                </a>
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ marginLeft: "3px", textDecoration:"none", fontSize: "12px" }}>
                <a
                  href="https://www.greenlandsadmin.com.au/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </form>
        </div>
        <div className="login__image__container">
          <video autoPlay src={loginVid} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
