import React, { useEffect, useState, useRef } from "react";
// Components
import SidePanel from "../common/sidePanel";
import BottomPanel from "../common/bottomPanel";
//icons
import "../../../styles/driver.css";
import "../../../styles/contact.css";
import { CiLocationArrow1 } from "react-icons/ci";
import { MdAdd } from "react-icons/md";
import { getAllDriverMessages, sendMessage } from "../../../api/apiDriver";
import Loading from "../../common/Loading";

const DriverContact = () => {
  const [driverMessage, setDriverMessage] = useState("");
  const [driverMessages, setDriverMessages] = useState([]);
  const [messageDone, setMessageDone] = useState(0);
  const driverId = localStorage.getItem("userID");
  const containerRef = useRef(null);
  const endOfMessageRef = useRef(null);
  const images = [];
  const [dataLoading, setDataLoading] = useState(true);

  const sentMessage = async (e) => {
    e.preventDefault();

    try {
      const data = await sendMessage(driverId, driverMessage, images);
      if (data.success === true) {
        setMessageDone(messageDone + 1);
        setDriverMessage("");
      }
    } catch (err) {
      console.error("Error sending message" + err);
    }
  };

  // Add images
  // const addImages = async (e) => {
  //   try {
  //     const files = Array.from(e.target.files);
  //     setImages((prevImages) => [...prevImages, ...files]);
  //     if (images != []) {
  //       const message = "";
  //       const response = await sendMessage(driverId, message, images);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // Read all messages

  // Run When component on mount
  useEffect(() => {
    const readMessages = async () => {
      try {
        const response = await getAllDriverMessages(driverId);
        setDriverMessages(response.ChatList);
        setDataLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    readMessages();
  }, [messageDone, driverId]);

  useEffect(() => {
    if (endOfMessageRef.current)
      endOfMessageRef.current.scrollIntoView({ behavior: "instant" });
  }, [driverMessage]);

  return (
    <>
      <div className="driver__contact__desk">
        <SidePanel />
        <div className="driver__message__container">
          {/* Desktop Version */}
          <h1 className="title">CONTACT ADMINISTRATOR</h1>
          <div className="driver__messaging__container">
            {driverMessages
              .slice()
              .reverse()
              .map((message) =>
                message && message.ChatSender && message.Message ? (
                  <div
                    key={message.ChatID}
                    className={`${
                      message.ChatSender === "DRIVER" ? "sender" : "reciever"
                    }`}
                  >
                    <p className="text">{message.Message}</p>
                  </div>
                ) : null
              )}

            <div className="driver_message__writter__container__desk">
              <button className="m__wm__button__send">
                <MdAdd size={30} />
              </button>
              <input
                className="message__writter__desk text"
                aria-autocomplete="inline"
                autoCorrect="on"
                spellCheck="true"
                contentEditable="true"
                placeholder="Type Your Message Here"
                onChange={(e) => setDriverMessage(e.target.value)}
              />

              <button onClick={sentMessage} className="m__wm__button__send">
                <CiLocationArrow1 size={30} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile version */}
      {dataLoading === true ? (
        <Loading />
      ) : (
        <div className="driver__contact_mob">
          <div className="administrator__messaging__container__mob">
            <h1 className="title">CONTACT ADMINISTRATOR</h1>
            <div ref={containerRef} className="mobile-msg-container">
              {driverMessages
                .slice()
                .reverse()
                .map((message) =>
                  message && message.ChatSender && message.Message ? (
                    <div
                      key={message.ChatID}
                      className={`${
                        message.ChatSender === "DRIVER"
                          ? "sender__mob"
                          : "reciever__mob"
                      }`}
                    >
                      <div>
                        <p className="heading-3">{message.Message}</p>
                      </div>
                    </div>
                  ) : null
                )}
              <div ref={endOfMessageRef} />
            </div>
          </div>
          <div className="message__writter__container__mob">
            {/* <label className="m__wm__button__send" htmlFor="add-image">
            {" "}
            <MdAdd size={30} />
          </label>
          <input
            type="file"
            acccept="image/"
            id="add-image"
            hidden
            onChange={addImages}
          /> */}

            <input
              className="message__writter__mob text"
              aria-autocomplete="inline"
              autoCorrect="on"
              spellCheck="true"
              contentEditable="true"
              placeholder="Type Your Message Here"
              value={driverMessage}
              onChange={(e) => setDriverMessage(e.target.value)}
            />

            <button onClick={sentMessage} className="m__wm__button__send">
              <CiLocationArrow1 size={30} />
            </button>
          </div>
          <BottomPanel location={"messages"} />
        </div>
      )}
    </>
  );
};

export default DriverContact;
