import React from "react";
import "../../../styles/administrator.css";
import Sidepanel from "../common/sidepanel";
import BottomPanel from "../common/bottomPanel";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const logout = () => {
    navigate("/");
  };
  return (
    <>
      <div className="administrator__settings__container">
        <Sidepanel />
        <div className="administrator__settings__content">
          <h1 className="title">SETTINGS</h1>
          <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ marginLeft: "3px", textDecoration:"none", fontSize: "12px" }}>
                <a
                  href="https://www.greenlandsadmin.com.au/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          <h2 className="heading-2">Change Username</h2>
          <input type="text" className="input" placeholder="New User Name" />
          <button className="button button-2">Change Username</button>
          <h2 className="heading-2 as__change__password">Change Password</h2>
          <input type="text" className="input" placeholder="Current Password" />
          <input type="text" className="input" placeholder="New Password" />
          <input
            type="text"
            className="input"
            placeholder="Confirm New Password"
          />
          <button className="button button-2">Change Password</button>
          <button
            className="button button-3"
            onClick={() => {
              logout();
            }}
          >
            Log Out
          </button>
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default Settings;
