import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// data
import { getDrops } from "../../../api/apiService";

const Selectdrop = () => {
  // Navigation
  const navigate = useNavigate(null);
  const [dropSearch, setDropSearch] = useState("");
  const [driverSearch, setDriverSearch] = useState("");
  const [dateSearch, setDateSearch] = useState("");
  const [dropDetails, setDropDetails] = useState([]);
  const [dropData, setDropData] = useState([]);
  const [dropId, setDropId] = useState("");

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  console.log(dropData);
  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };

  useEffect(() => {
    const getAllDropData = async () => {
      try {
        const response = await getDrops();
        if (response.success === true) {
          setDropData(response.driverInfo);
        }

        // Convert to array
        const dropInfoArray = Array.isArray(response.driverInfo)
          ? response.driverInfo
          : [response.driverInfo];

        setDropDetails(dropInfoArray);
      } catch (err) {
        console.error(err);
        // window.location.href("/");
      }
    };

    getAllDropData();
  }, []);

  // Select Particular Drop
  const selectDrop = (index) => {
    setDropId(dropData[index].DropID);
  };

  useEffect(() => {
    navigate(`/administrator/${dropId}`);
  }, [dropId, navigate]);

  console.log(dropData[3]);

  return (
    <section>
      <div className="select__drop__mc">
        <div className="select__drop__search__c">
          <input
            onChange={(e) => setDropSearch(e.target.value.toLowerCase())}
            type="text"
            className="heading-3 "
            placeholder="Drop Name/Address"
          />

          <input
            onChange={(e) => setDriverSearch(e.target.value.toLowerCase())}
            type="text"
            className="input"
            placeholder="Driver"
          />

          <input
            onChange={(e) => setDateSearch(e.target.value)}
            type="date"
            className="heading-3"
            placeholder="Select Date"
          />
        </div>
        <div>
          <table className="drop__table">
            <thead >
              <tr className="heading-2">
                <th>DROP NAME</th>
                <th>DRIVER NAME</th>
                <th>RUNNING DATE</th>
                <th>ADDRESS</th>
                <th>STATUS</th>
                <th>TIME</th>
              </tr>
            </thead>

            <tbody className="drop-table-body">
              {/* Search Using dropname and address */}
              {/* Drop search function */}
              {dropDetails
                .filter((drop) => {
                  const searchDrop =
                    drop.DropName.toLowerCase().includes(dropSearch) ||
                    drop.Location.toLowerCase().includes(dropSearch);
                  const searchDriver =
                    drop.DriverName.toLowerCase().includes(driverSearch);
                  const searchDate = formatDate(drop.DateTime).includes(
                    dateSearch
                  );
                  return dropSearch === "" &&
                    driverSearch === "" &&
                    dateSearch === ""
                    ? drop
                    : searchDrop && searchDriver && searchDate;
                })
                .map((drop, index) => (
                  <tr
                    key={index}
                    className="text drop__table__row"
                    onClick={() => selectDrop(index)}
                  >
                    <td>{drop.DropName}</td>
                    <td>{drop.DriverName}</td>
                    <td>{formatDate(drop.DateTime)}</td>
                    <td>{drop.Location}</td>
                    <td ><p className={`drop-table-status ${
                      drop.DropStatus === "Todo" ? "drop-status-todo" : drop.DropStatus === "InProgress" ? "drop-status-inprogress" : "drop-status-completed"


                    }`}>{drop.DropStatus}</p></td>
                    <td>{formatTime(drop.DateTime)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="select__drop__main__container__mobile">
        <div className="select__drop__search__bars">
          <input
            onChange={(e) => setDropSearch(e.target.value.toLowerCase())}
            type="text"
            className="input"
            placeholder="Drop Name / Address"
          />
          <input
            onChange={(e) => setDriverSearch(e.target.value.toLowerCase())}
            type="text"
            className="input"
            placeholder="Driver"
          />

          <input type="date" className="input" placeholder="Select Date" />
        </div>
        <div className="select__drop__cards__container">
          {dropDetails
            .filter((drop) => {
              const searchDrop =
                drop.DropName.toLowerCase().includes(dropSearch) ||
                drop.Location.toLowerCase().includes(dropSearch);

              const searchDriver =
                drop.DriverName.toLowerCase().includes(driverSearch);

              const searchDate = formatDate(drop.DateTime).includes(dateSearch);

              return dropSearch === "" &&
                driverSearch === "" &&
                dateSearch === ""
                ? drop
                : searchDrop && searchDriver && searchDate;
            })
            .map((drop, index) => (
              <div onClick={() => selectDrop(index)} className="sdcc__Link">
                <div key={index} className="select__drop__card">
                  <div className="select__drop__card__col col1">
                    <p className="heading-2">{drop.DropName}</p>
                    <p className="select__drop__tag heading-3 ">
                      {drop.DropStatus}
                    </p>
                    <p className="text">{drop.Location}</p>
                  </div>
                  <div className="select__drop__card__col col2">
                    <p className="heading-2">{drop.DriverName}</p>
                    <p className="text">{formatDate(drop.DateTime)}</p>
                    <p className="text">{formatTime(drop.DateTime)}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Selectdrop;
