import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BottomPanel from "../common/bottomPanel";
import "../../../styles/driver/components/drop-edit.css";
import { dropInformation } from "../../../api/apiDriver";
import Loading from "../../common/Loading";

const DropEdit = () => {
  const [dateTime, setDateTime] = useState(null);
  const [dropComment, setDropComment] = useState();
  const [dropName, setDropName] = useState(null);
  const [dropStatus, setDropStatus] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);
  const [dataFetchingError, setDataFetchingError] = useState(false);
  // const [newDropComment, setNewDropComment] = useState(null);
  const { id } = useParams();
  const [loading, isLoading] = useState(true);

  //  fetch dropdata
  useEffect(() => {
    const showDriverInformation = async () => {
      try {
        const response = await dropInformation(id);
        if (response.success === true) {
          isLoading(false);
          setDateTime(response.dropInfo[0].DateTime);
          if (response.dropInfo[0].DropComment === null) {
            setDropComment("No Comment");
          }
          setDropComment(response.dropInfo[0].DropComment);
          setDropName(response.dropInfo[0].DropName);
          setDropStatus(response.dropInfo[0].DropStatus);
          setDropLocation(response.dropInfo[0].Location);
        } else {
          setDataFetchingError(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    showDriverInformation();
  }, [id]);

  //convert date and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };

  // change drop details
  // const changeDropComment = async () => {
  //   try {
  //     const response = await updateDrop(id, newDropComment);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <div className="drop-edit-main">
          <div className="drop-edit-top">
            <h3 className="heading-2">Edit Drop</h3>
            <h3 className="heading-2">
              {dataFetchingError === false ? dropName : null}
            </h3>
            <p className="text">
              {dataFetchingError === false ? dropStatus : null}
            </p>
          </div>
          <div className="drop-edit-datetime">
            <p className="heading-3">
              {formatDate(dataFetchingError === false ? dateTime : null)}
            </p>
            <p className="heading-3">
              {formatTime(dataFetchingError === false ? dateTime : null)}
            </p>
          </div>
          <p className="heading-3 drop-edit-location">
            {dataFetchingError === false ? dropLocation : null}
          </p>
          <div className="drop-edit-comment">
            <h3 className="heading-2">Change Comment</h3>
            <textarea
              placeholder={dropComment}

              className="drop-edit-comment-textarea input"
            ></textarea>
            <button className="button">Change Comment</button>
          </div>
          {/* <div>
          <img src="" alt="" />
          <button>Add Images</button>
        </div> */}
        </div>
      )}

      <BottomPanel />
    </>
  );
};

export default DropEdit;
