import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ADMINROUTES,
  ROUTES,
  DRIVERROUTES,
  TESTROUTES,
  LEGALROUTES,
} from "./routes";
import Dashboard from "./pages/administrator/dashboard";

import DashboardDriver from "./pages/driver/dashboard";
import SelectedDrop from "./components/administrator/dashboard/selectedDrop";
import DriverManagement from "./pages/administrator/driverManagement";
import DriverHistory from "./components/driver/driverHistory/driverHistory";
import Settings from "./components/administrator/settings/settings";
import Messaging from "./components/administrator/contact/messaging";
import Login from "./pages/login";
import IndividualMessaging from "./components/administrator/contact/individualMessaging";
import ComingSoon from "./pages/comingSoon";
import DriverContact from "./components/driver/contact/driverContact";
import SettingsDR from "./components/driver/settings/settings";
import AdministratorCheck from "./components/administrator/dashboard/AdministratorCheck";
import AuthProvider from "./provider/authProvider";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import IndividualDrop from "./components/driver/dashboard/individualDrop";
// Tostify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Help from "./components/administrator/help/help";
import DriverSign from "./components/driver/common/DriverSign";
import DataCollection from "./pages/leagal-docs/data-collection/DataCollection";
import PrivacyPolicy from "./pages/leagal-docs/privacy-policy/PrivacyPolicy";
import TermsOfUse from "./pages/leagal-docs/terms-of-use/TermsOfUse";
import PrivacyPolicyMain from "./pages/privacyPolicyMain";
import DropEdit from "./components/driver/driverHistory/DropEdit";
import DriverTracking from "./pages/administrator/driverTracking";

function App() {
  return (
    <BrowserRouter>
      <main>
        <AuthProvider>
          <Routes>
            {/* common routes */}
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.COMINGSOON} element={<ComingSoon />} />

            {/* Legal Routes (Public) */}
            <Route
              path={LEGALROUTES.PRIVACYPOLICYMAIN}
              element={<PrivacyPolicyMain />}
            />
            <Route
              path={LEGALROUTES.DATACOLLECTION}
              element={<DataCollection />}
            />
            <Route
              path={LEGALROUTES.PRIVACYPOLICY}
              element={<PrivacyPolicy />}
            />
            <Route path={LEGALROUTES.TERMSOFUSE} element={<TermsOfUse />} />

            {/* Administrator Routes */}
            <Route element={<ProtectedRoute requiredRole="Admin" />}>
            
              <Route path={ADMINROUTES.DASHBOARD} element={<Dashboard />} />
              <Route
                path={ADMINROUTES.SELECTEDDROP}
                element={<SelectedDrop />}
              />
              <Route
                path={ADMINROUTES.DRIVERMANAGEMENT}
                element={<DriverManagement />}
              />
              <Route
                path={ADMINROUTES.DriverTracking}
                element={<DriverTracking />}
              />
              <Route path={ADMINROUTES.SETTINGS} element={<Settings />} />
              <Route path={ADMINROUTES.MESSAGING} element={<Messaging />} />
              <Route
                path={ADMINROUTES.INDIVIDUALMESSAGING}
                element={<IndividualMessaging />}
              />
              <Route path={ADMINROUTES.HELP} element={<Help />} />
            </Route>

            {/* Driver Routes */}
            <Route element={<ProtectedRoute requiredRole="Driver" />}>
              <Route
                path={DRIVERROUTES.DASHBOARD}
                element={<DashboardDriver />}
              />
              <Route
                path={DRIVERROUTES.SPECIFICDROP}
                element={<IndividualDrop />}
              />
              <Route
                path={DRIVERROUTES.DRIVERHISTORY}
                element={<DriverHistory />}
              />
              <Route path={DRIVERROUTES.DROPEDIT} element={<DropEdit />} />

              <Route
                path={DRIVERROUTES.MESSAGING}
                element={<DriverContact />}
              />
              <Route path={DRIVERROUTES.SETTINGS} element={<SettingsDR />} />
              {/* Test Routes */}
              <Route
                path={TESTROUTES.LOGINTEST}
                element={<AdministratorCheck />}
              />
              <Route path={DRIVERROUTES.SIGN} element={<DriverSign />} />
            </Route>
            
          </Routes>
        </AuthProvider>
        <ToastContainer className="notifications" />
      </main>
    </BrowserRouter>
  );
}

export default App;
