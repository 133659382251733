import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

export const ProtectedRoute = ({requiredRole}) => {
  const { token, userRole } = useAuth();



  //    Check user is authenticates
  if (!token) {
    // IF not authenticated redirect to login page
    return (
      <Navigate
        to="/"
      />
    );
  }

  if (userRole !== requiredRole) {
    return (
      <Navigate
        to="/"
      />
    );
  }

  //   If authenticated render child components
  return <Outlet />;
};
 