import React, { useState } from "react";
import Sidepanel from "../common/sidepanel";
import { sendHelpRequest } from "../../../api/apiService";
import { ToastContainer, toast, Bounce } from "react-toastify";

const Help = () => {
  const [email, setEmail] = useState("admin@greenlands.com");
  const [subject, setSubject] = useState("This is default subject");
  const [message, setMessage] = useState("This is default Message");
  const userId = localStorage.getItem("userID");

  const sendMail = async () => {
    try {
      const response = await sendHelpRequest(userId, email, subject, message);

      if (subject === "This is default subject") {
        toast.warning("Please Enter Subject", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else if (message === "This is default Message") {
        toast.warning("Please Enter Message", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        if (response.data.success === true) {
          toast.success("Successfully Send Help Request", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error("Failed to Send Help Request", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="help-mc">
        <Sidepanel />

        <div className="help-input-container">
          <h1 className="heading-1">Help</h1>
          <div className="help-inputs">
            <input
              type="email"
              placeholder="admin@greenlands.com"
              onChange={(e) => setEmail(e.target.value)}
              className="input"
            />
            <input
              type="text"
              placeholder="Subject"
              className="input"
              onChange={(e) => setSubject(e.target.value)}
            />
            <input
              type="textarea"
                rows="10"
              name="message"
            
              id="helpMessage"
              placeholder="Message"
              className="input"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <input
            type="button"
            value="Send Email"
            className="button button-1"
            onClick={sendMail}
          />
        </div>
      </div>
      <ToastContainer className="notifications" />
    </>
  );
};

export default Help;
