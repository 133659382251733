const createDriverValidation = async (
  driverName,
  contactNumber,
  password,
  confirmPassword
) => {
  if (driverName === null || driverName.trim() === "") {
    alert("Please enter driver name");
  }
  if (driverName.length < 3) {
    alert("Driver name must be at least 3 characters long");
    return false;
  }
  if (contactNumber === null || contactNumber.trim() === "") {
    alert("Please enter contact number");
  }
  if (contactNumber.length < 9) {
    alert("Contact number must be at least 9 digits long");
    return false;
  }
  if (password === null || password.trim() === "") {
    alert("Please enter password");
  }
  if (password.length < 8) {
    alert("Password must be at least 8 characters long");
    return false;
  }
  if (confirmPassword === null || confirmPassword.trim() === "") {
    alert("Please enter confirm password");
  }
  if (confirmPassword !== password) {
    alert("Password and Confirm Password should be same");
    return false;
  } else {
    return true;
  }
};

const updateDriverValidation = async (driverName, password) => {
  try {
    if (driverName === null || driverName.trim() === "") {
      alert("Please enter driver name");
      return false;
    }
    if (driverName.length < 3) {
      alert("Driver name must be at least 3 characters long");
      return false;
    }
    if (password === null || password.trim() === "") {
      alert("Please enter password");
      return false;
    }
    if (password.length < 8) {
      alert("Password must be at least 6 characters long");
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.warn(error.message);
    throw error;
  }
};
export { createDriverValidation, updateDriverValidation };
