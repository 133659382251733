// src/firebaseService.js
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
  onSnapshot
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDh_AMbY0Dtft-Jg74RwwXVeTRe5MGK7Sc",
  authDomain: "greenland-b663b.firebaseapp.com",
  projectId: "greenland-b663b",
  storageBucket: "greenland-b663b.appspot.com",
  messagingSenderId: "594370505442",
  appId: "1:594370505442:web:4d4e3794f630ccdb634cbb",
  measurementId: "G-QYGZRR0NSC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

// Function to handle anonymous login
const anonymousLogin = async () => {
  try {
    const result = await signInAnonymously(auth);
    console.log("User signed in anonymously", result.user);
    return result.user;
  } catch (error) {
    console.error("Error during anonymous login", error);
    throw error;
  }
};

// Function to set up a real-time listener for a collection
// Function to set up a real-time listener for a collection
const setupCollectionListener = (collectionName, onChange) => {
  const collectionRef = collection(firestore, collectionName);
  return onSnapshot(collectionRef, (snapshot) => {
    const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    onChange(data);
  });
};

// Function to handle auth state changes
const onAuthChange = (callback) => {
  // Set up an observer on the Auth object
  return onAuthStateChanged(auth, (user) => {
    // Call the callback function with the user object
    callback(user);
  });
};

// Function to fetch data from Firestore
const fetchData = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(firestore, collectionName));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return data;
  } catch (error) {
    console.error("Error fetching data from Firestore", error);
    throw error;
  }
};
// Function to save data to Firestore
// Function to save or update data in Firestore
const saveOrUpdateData = async (collectionName, docId, data) => {
  try {
    const docRef = doc(firestore, collectionName, docId);
    await setDoc(docRef, data, { merge: true });
    console.log("Document created/updated with ID: ", docId);
  } catch (error) {
    console.error("Error creating/updating document: ", error);
    throw error;
  }
};

export {
  anonymousLogin,
  onAuthChange,
  fetchData,
  saveOrUpdateData,
  setupCollectionListener,
};
