
import React from "react";
import "../../../styles/privacy-policy.css";
import privacyPolicyImg from '../../../assets/privacy-policy/privacy-policy.svg'

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-main-container">
        <div className="privacy-image-container">
          <img src={ privacyPolicyImg } alt="" />
        </div>
        <div className="privacy-title-container">
            <h2 className="title">Greenlands Grocer Privacy Policy</h2>
            <p className="effective-date">Effective Date: [Insert Date]</p>

            <h3 className="heading-1">Introduction</h3>
            <p className="heading-2">
                Greenlands Grocer ("Greenlands Grocer," "we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website (<a href="https://www.greenlandsgrocer.com.au" target="_blank" rel="noopener noreferrer">https://www.greenlandsgrocer.com.au</a>), use our mobile application, or interact with our services. Please read this policy carefully to understand our views and practices regarding your personal information and how we will treat it.
            </p>

            <h3 className="heading-1">1. Information We Collect</h3>
            <p className="heading-2">We may collect and process the following types of information about you:</p>
            <ul className="heading-2">
                <li>Personal Information: Name, address, email address, phone number, date of birth, and other identifiers by which you may be contacted or identified.</li>
                <li>Employment Information: Details such as job title, department, work history, performance evaluations, and payroll information.</li>
                <li>Financial Information: Bank account numbers, payment details, and other financial data necessary for payroll processing.</li>
                <li>Technical Data: IP address, login data, browser type and version, time zone setting, browser plug-in types, and other technology on the devices you use to access our website or application.</li>
                <li>Usage Data: Information about how you use our website, products, and services, including access times, pages viewed, and actions taken.</li>
                <li>Location Data: If you use our delivery services or application, we may collect real-time location data.</li>
            </ul>

            <h3 className="heading-1">2. How We Collect Information</h3>
            <p className="heading-2">We collect information in the following ways:</p>
            <ul className="heading-2">
                <li>Direct Interactions: When you fill out forms, apply for a job, subscribe to our services, enter information on our website, or communicate with us by phone, email, or otherwise.</li>
                <li>Automated Technologies: As you interact with our website, we may collect technical data about your equipment, browsing actions, and patterns through cookies, server logs, and other similar technologies.</li>
                <li>Third Parties: We may receive personal data about you from various third parties, including analytics providers, advertising networks, search information providers, and others.</li>
            </ul>

            <h3 className="heading-1">3. How We Use Your Information</h3>
            <p className="heading-2">We use the information we collect for the following purposes:</p>
            <ul className="heading-2">
                <li>To Provide Our Services: Including managing your account, processing transactions, and delivering products.</li>
                <li>To Improve Our Services: By analyzing your interactions with our website and application to enhance user experience and functionality.</li>
                <li>To Communicate With You: About your account, orders, or employment, as well as to provide updates, newsletters, and other relevant information.</li>
                <li>To Comply With Legal Obligations: Including record-keeping, tax compliance, and responding to lawful requests from public authorities.</li>
                <li>For Employment Purposes: Managing payroll, assessing performance, and fulfilling our obligations as an employer.</li>
                <li>For Security and Fraud Prevention: Protecting our business, website, and users from fraud and other illegal activities.</li>
            </ul>

            <h3 className="heading-1">4. How We Share Your Information</h3>
            <p className="heading-2">We may share your personal information with the following parties:</p>
            <ul className="heading-2">
                <li>Service Providers: Companies that provide services on our behalf, such as payment processing, IT services, and analytics.</li>
                <li>Affiliates and Subsidiaries: For business operations and improvements.</li>
                <li>Legal Authorities: If required by law or to protect our rights and property.</li>
                <li>Business Transfers: In the event of a merger, acquisition, or sale of our assets.</li>
            </ul>

            <h3 className="heading-1">5. Cookies and Tracking Technologies</h3>
            <p className="heading-2">
                Our website uses cookies and similar tracking technologies to enhance user experience. Cookies are small files placed on your device that help us understand how our website is used. You can control the use of cookies through your browser settings.
            </p>

            <h3 className="heading-1">6. Data Security</h3>
            <p className="heading-2">
                We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no internet-based service can be 100% secure, and we cannot guarantee the absolute security of your data.
            </p>

            <h3 className="heading-1">7. Data Retention</h3>
            <p className="heading-2">
                We retain your personal information only for as long as necessary to fulfill the purposes we collected it for, including legal, accounting, or reporting requirements.
            </p>

            <h3 className="heading-1">8. Your Rights</h3>
            <p className="heading-2">You have the right to:</p>
            <ul className="heading-2">
                <li>Access Your Data: Request a copy of the personal data we hold about you.</li>
                <li>Correct Your Data: Request corrections to any inaccurate or incomplete data.</li>
                <li>Delete Your Data: Request deletion of your personal data, subject to certain legal obligations.</li>
                <li>Object to Processing: Object to the processing of your personal data where we rely on legitimate interests.</li>
                <li>Data Portability: Request the transfer of your personal data to another party.</li>
            </ul>
            <p className="heading-2">To exercise these rights, please contact us at [insert contact information].</p>

            <h3 className="heading-1">9. International Transfers</h3>
            <p className="heading-2">
                Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country.
            </p>

            <h3 className="heading-1">10. Links to Other Websites</h3>
            <p className="heading-2">
                Our website may contain links to other websites that are not operated by us. We are not responsible for the content, privacy policies, or practices of third-party websites.
            </p>

            <h3 className="heading-1">11. Changes to This Privacy Policy</h3>
            <p className="heading-2">
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h3 className="heading-1">12. Contact Us</h3>
            <p className="heading-2">
                If you have any questions about this Privacy Policy or our data practices, please contact us at:
            </p>
            <p className="heading-2">
                Address: Greenlands Grocer <br />
                264 Liverpool Road, <br />
                Kilsyth South VIC 3137, <br />
                Australia.
            </p>
            <p className="heading-2">Email: <a href="mailto:sales@greenlandsfruit.com.au">sales@greenlandsfruit.com.au</a></p>
            <p className="heading-2">Phone: (03) 9728 6989</p><br></br>
      </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
