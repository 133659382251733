
import React from 'react';
import StaffImage from '../../../assets/data-collection/data-collection.svg';
import '../../../styles/staff-collection.css';
import { Link } from 'react-router-dom';

const DataCollection = () => {
  return (
    <div className="staff-collection-main-container">
        <div className="staff-image-container">
          <img src={StaffImage} alt="" />
        </div>
        <div className="staff-title-container">
          <h1 className='title'>Greenlands Grocer – Staff Data Collection Notice</h1><br></br>
          <h3 className='heading-1'>Privacy Commitment</h3><br></br>
          <p className='heading-2'>
            Greenlands Grocer (Pvt) Ltd. (referred to as "we," "our," or "us")
            values the privacy of all users of our application. This notice
            outlines how we collect, use, and handle your personal information
            when you interact with our application and during your engagement
            with Greenlands Grocer.
          </p><br></br>
        </div>
        <div className="staff-desctiption-container">
          <h3 className='heading-1'>What Information Do We Collect?</h3><br></br>
          <p className='heading-2'>
            Greenlands Grocer will collect, use, and manage your personal
            information at the start of and throughout your engagement with us.
            The types of information we collect include:
          </p><br></br>
          <ul className='heading-2'>
            <li>
              Contact Information: Full name, address, phone number, and
              emergency contact details.
            </li>
            <li>
              Identification Information: Citizenship or residency status,
              passport details, and any relevant work permits or visas
            </li>
            <li>
              Employment Details: Position, department, employment start date,
              and relevant work history.
            </li>
            <li>
              Financial Information: Bank account details for payroll, tax
              identification number, and superannuation information.
            </li>
            <li>
              Application Usage Data: When you use our app, we collect data
              related to your activities, such as login times, IP address,
              device information, and any actions taken within the app.
            </li>
            <li>
              Performance Data: Information on work performance, attendance, and
              any feedback provided through the app.
            </li>
            <li>
              Location Data: If the app requires it, we may collect real-time
              location data for delivery operations and tracking purposes.
            </li><br></br>
          </ul>
        </div>
        <div className="staff-desctiption-container">
          <h3 className='heading-1'>How Do We Use Your Information?</h3><br></br>
          <p className='heading-2'>
            Greenlands Grocer collects and uses your personal information for
            the following purposes:
          </p><br></br>
          <ul className='heading-2'>
            <li>
              Employment Administration: Managing payroll, benefits, and
              employment records.
            </li>
            <li>
              Operational Efficiency: Enhancing the efficiency of our delivery
              operations and other app-related tasks.
            </li>
            <li>
              Compliance: Ensuring adherence to legal and regulatory
              requirements
            </li>
            <li>
              Performance Monitoring: Assessing work performance, ensuring
              safety, and improving operational processes.
            </li>
            <li>
              Communication: Keeping you informed about important updates,
              changes to procedures, or other relevant information.
            </li>
          </ul>
        </div><br></br>
        <div className="staff-desctiption-container">
          <h3 className='heading-1'>Who Do We Share Your Information With?</h3><br></br>
          <p className='heading-2'>We may share your personal information with:</p><br></br>
          <ul className='heading-2'>
            <li>
              Internal Departments: For the purposes of HR management, payroll
              processing, and performance evaluation.
            </li>
            <li>
              Third-Party Service Providers: Such as cloud service providers,
              data analytics companies, and IT support, strictly for the
              purposes of managing and improving the app's functionality.
            </li>
            <li>Regulatory Bodies: As required by law or regulation.</li>
          </ul>
        </div><br></br>
        <div className="staff-desctiption-container">
          <h3 className='heading-1'>Your Consent</h3><br></br>
          <p className='heading-2'>
            By using our application, you consent to the collection, use, and
            disclosure of your personal information as described in this notice.
            If you do not consent to the collection of your personal
            information, you may be unable to access certain features of the
            application or fully engage in your role with Greenlands Grocer.
          </p>
        </div><br></br>
        <div className="staff-desctiption-container">
          <h3 className='heading-1'>Where Can I Get More Information?</h3><br></br>
          <p className='heading-2'>
            For more detailed information on how we handle your personal
            information, please refer to our comprehensive <Link to='/privacy-policy'>Privacy Policy</Link>, which
            includes details on accessing your information, requesting
            corrections, and lodging privacy complaints.
          </p><br></br>
        </div>
      </div>
  )
}

export default DataCollection
