import {createContext, useContext, useState} from "react";
// create context for global state
const DeleteContext = createContext();

// provider component
export const DeleteProvider = ({children}) =>{
  const [deleteDrop, setDeleteDrop] = useState(0);
  return(
    <DeleteContext.Provider value={{deleteDrop, setDeleteDrop}}>
      {children}
    </DeleteContext.Provider>
  )
  
}

// custom hook to use DropContext
export const useDeleteContext = () =>{
  return useContext(DeleteContext);
}