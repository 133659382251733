import React, { useState } from "react";
// import "../../../styles/administrator.css";
import "../../../styles/driver.css";
import Sidepanel from "../common/sidePanel";
import BottomPanel from "../common/bottomPanel";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce, } from "react-toastify";
import { passwordChange } from "../../../api/apiDriver";

const SettingsDR = () => {
  const navigate = useNavigate();
  const logout = () => {
    navigate("/");
  }
  const currentPassword = localStorage.getItem("password");
  const [userCurrentPassword, setUserCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const contactNo = localStorage.getItem("contactNo");

  const handleCurrentPasswordChange = async (e) => {
    e.preventDefault();
    if (userCurrentPassword !== currentPassword) {
      toast.error("Your Current Passsword is Wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      if (currentPassword !== newPassword) {
        if (newPassword !== confirmNewPassword) {
          toast.error("Password Confirmation Not Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          try {
            const response = await passwordChange(
              contactNo,
              currentPassword,
              newPassword
            );
            if (response.data.success === true) {
              toast.success("Password change successful", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        toast.error("You are tryning to assign same password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }
  };
  // navigate to sign in
  const navigateToSign = () => {
    navigate("/driver/sign");
  };
 

  return (
    <>
      <div className="administrator__settings__container">
        <Sidepanel />
        <div className="administrator__settings__content">
          <h1 className="title">SETTINGS</h1>
          <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ marginLeft: "3px", textDecoration:"none", fontSize: "12px" }}>
                <a
                  href="https://www.greenlandsadmin.com.au/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          <h2 className="heading-2 as__change__password">Change Password</h2>
          <input
            type="text"
            className="input"
            placeholder="Current Password"
            onChange={(e) => setUserCurrentPassword(e.target.value)}
          />
          <input
            type="text"
            className="input"
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="text"
            className="input"
            placeholder="Confirm New Password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <button
            className="button button-2"
            onClick={handleCurrentPasswordChange}
          >
            Change Password
          </button>
          <button
            onClick={() => {
              logout();
            }}
            className="button button-3"
          >
            Log Out
          </button>
          <button className="button" onClick={navigateToSign}>
            Driver Sign
          </button>
        </div>
        <ToastContainer />
       
        <BottomPanel location={"settings"}/>
      </div>
    </>
  );
};

export default SettingsDR;
