import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "../../styles/common.css";

const Loading = () => {
  return (
    <>
      <div className="loading-main">
        <Box
          sx={{ display: "flex", marginLeft: "10px" }}
  
        >
          <CircularProgress />
        </Box>

      
      </div>
    </>
  );
};

export default Loading;
