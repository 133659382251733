import { useState, useEffect } from "react";
import axios from "axios";


const AdministratorCheck = () => {
  const [admin, setAdmin] = useState();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getAdmin = async () => {
      try {
        const response = await axios.get("/access/login/valodation", {
          signal: controller.signal,
        });
        console.log(response.data);
        isMounted && setAdmin(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getAdmin();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <article>
      <h2>Admin List</h2>
      {admin?.length ? (
        <ul>
          {admin.map((admin, i) => (
            <li key={i}>{admin?.email}</li>
          ))}
        </ul>
      ) : (
        <p>No administrators available</p>
      )}
    </article>
  );
};

export default AdministratorCheck;
