import { React, useCallback, useEffect, useState } from "react";
import "./placesInput";
import GoogleMaps from "./placesInput";
import { sendDropData } from "../../../api/apiDriver";
import { useDropContext } from "../../../provider/AddDropProvider";
import { toast, Bounce } from "react-toastify";
import { ToastContainer } from "react-toastify";

const Adddrop = () => {
  const [selectedPlace, setSelectedPlace] = useState({});
  const { addDrop, setAddDrop } = useDropContext();
  const [dropName, setDropName] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [dropType, setDropType] = useState("");
  const [readyAddDrop, setReadyAddDrop] = useState(false);

  // Place select fucntion
  const handlePlaceSelect = (place) => {
    console.log(place);
    setSelectedPlace(place);
  };

  // Clear Google maps inputs
  // Equal to clear child input
  const clearGoogleAPIIn = (resetInput) => {
    resetInput();
  };

  console.log(addDrop);

  const handleAddDrop = async () => {
    try {
      if (selectedPlace.terms) {
        setDropName(selectedPlace.description);
        setDropLocation(selectedPlace.terms[0].value);
        setDropType("Business");
        setReadyAddDrop(true);
      } else {
        setDropName(selectedPlace.description);
        setDropLocation(selectedPlace.description);
        setDropType("Residential");
        setReadyAddDrop(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addDropHandler = useCallback(async () => {
    try {
      const driverId = localStorage.getItem("userID");
      const response = await sendDropData(
        driverId,
        dropName,
        dropLocation,
        dropType
      );

      // clearGoogleAPIIn();
      if (response.success) {
        toast.success("Drop Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setAddDrop((prev) => prev + 1);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in add Drop. Please Contact Administrator", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    // eslint-disable-next-line
  }, [dropName, dropLocation, dropType, selectedPlace, setAddDrop]);

  useEffect(() => {
    if (readyAddDrop) {
      // eslint-disable-next-line
      addDropHandler();
      setReadyAddDrop(false);
    }
    //eslint-disable-next-line
  }, [readyAddDrop]);

  return (
    <>
      <div className="driver__add__drop__container__desktop">
        <div className="driver__add__drop__search">
          <h3 className="heading-2">ADD DROP</h3>

          <GoogleMaps onPlaceSelect={handlePlaceSelect} />

          <button className="button button-1" onClick={handleAddDrop}>
            Add Drop
          </button>
        </div>
        <ToastContainer />
      </div>

      <div className="add__drop__container__mob">
        <h2 className="heading-2">ADD DROP</h2>
        <GoogleMaps
          onPlaceSelect={handlePlaceSelect}
          clearInput={clearGoogleAPIIn}
        />
        {/* <div className="add__drop__drop__location">
          <p className="text">Drop Location: </p>
          <div className="add__drop__drop__location__details">
            <p className="text">Address Line 1</p>
            <p className="text">Address Line 2</p>
            <p className="text">Address Lne 33</p>
          </div>
        </div> */}
        <button onClick={handleAddDrop} className="button button-1">
          Add Drop
        </button>
        <ToastContainer />
      </div>
    </>
  );
};

export default Adddrop;
