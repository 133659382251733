import React from "react";
import Sidepanel from "../../components/administrator/common/sidepanel";
import DriverRegistration from '../../components/administrator/driverManagement/driverRegistration'
import EditDriver from "../../components/administrator/driverManagement/editDriver";
import BottomPanel from "../../components/administrator/common/bottomPanel";

const driverManagement = () => {
  return (
    <>
      <div className="driver__management__container">
        <Sidepanel />
        <div className="driver__management__content">
          <h1 className="title">DRIVER MANAGEMENT</h1>
          <DriverRegistration/>
          <EditDriver/>
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default driverManagement;
