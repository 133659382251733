import React from "react";
import "../../styles/driver.css";
import Sidepanel from "../../components/driver/common/sidePanel";
import AddDrop from "../../components/driver/dashboard/adddrop";
import CurrentDrop from "../../components/driver/dashboard/currentDrop";
import BottomPanel from "../../components/driver/common/bottomPanel";
import CurrentState from "../../components/driver/dashboard/currentState";
import { DropProvider } from "../../provider/AddDropProvider";
import { DeleteProvider } from "../../provider/DeleteDropProvider";
import "../../styles/common.css";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const DashboardDriver = () => {
  const [dropDeleted, setDropDeleted] = useState(false);
  return (
    <>
      {/*Desktop view */}
      <div className="driver__dashboard__container__desktop">
        <Sidepanel className="side__panel__desktop" />
        <div className="driver__dashboard__panel">
          <h1 className="title">DASHBOARD</h1>
          <DeleteProvider>
            <DropProvider>
              <CurrentState />
              <AddDrop />
              <CurrentDrop />
            </DropProvider>
          </DeleteProvider>
        </div>
      </div>

      {/*Mobile view */}
      <div className="driver__dashboard__container__mob">
        <div className="driver__dashboard__content">
          <h1 className="title">DASHBOARD</h1>
          <DeleteProvider>
            <DropProvider>
              <CurrentState dropDeleted={dropDeleted} />
              <AddDrop />
              <CurrentDrop
                setDropDeleted={setDropDeleted}
                dropDeleted={dropDeleted}
              />
            </DropProvider>
          </DeleteProvider>
          <div>
            <BottomPanel location={"dashboard"}/>
            <Outlet/>
          </div>
    
        </div>
        
      </div>
    </>
  );
};

export default DashboardDriver;
