import { createContext, useContext, useState } from "react";
// create context for global state
const DropContext = createContext();

// provider component
export const DropProvider = ({ children }) => {
  const [addDrop, setAddDrop] = useState(0);
  return (
    <DropContext.Provider value={{ addDrop, setAddDrop }}>
      {children}
    </DropContext.Provider>
  );
};

// custom hook to use DropContext
export const useDropContext = () => {
  return useContext(DropContext);
};
