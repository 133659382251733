import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  currentDrops,
  deleteDropCall,
  inProgress,
  dropOrdering,
} from "../../../api/apiDriver";
import { Reorder } from "framer-motion";
import { useDropContext } from "../../../provider/AddDropProvider";
import { MdDeleteOutline } from "react-icons/md";
import { ToastContainer, toast, Bounce } from "react-toastify";
import NoData from "../dashboard/NoData";
import { IoIosArrowDropup } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";
const CurrentDrop = ({ setDropDeleted, dropDeleted }) => {
  const driverId = localStorage.getItem("userID");
  const [dropData, setDropData] = useState([]);
  //eslint-disable-next-line
  const [dropLocation, setDropLocation] = useState("");
  const [dropStatus, setDropStatus] = useState("");
  const [dropId, setDropId] = useState("");
  // eslint-disable-next-line
  const [dropName, setDropName] = useState("");
  const navigate = useNavigate()  ;
  const { addDrop } = useDropContext();
  const [dropDeleteId, setDropDeleteId] = useState("");
  const { deleteDrop } = useDropContext(); //setDeleteDrop remove due to build issues.
  const [deleteDropCurrentStateChange, setDeleteDropCurrentStateChange] =
    useState(0);
  const [isDropData, setIsDropData] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragItemStateCheck, setDragItemStateCheck] = useState(true);
  const [dropDeleting, setDropDeleting] = useState(null);
  // Run when dropdata
  useEffect(() => {
    const showCurrentDrops = async () => {
      try {
        const data = await currentDrops(driverId);
        if (data === undefined) {
          setIsDropData(false);
        } else {
          // return object convert to array
          const driverInfoArray = Array.isArray(data.driverInfo)
            ? data.driverInfo
            : [data.driverInfo];
          setDropData(driverInfoArray);
          setIsDropData(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    showCurrentDrops();
  }, [
    addDrop,
    driverId,
    deleteDrop,
    deleteDropCurrentStateChange,
    dropDeleteId,
    dragItemStateCheck,
  ]);

  //date format funtion
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      // eslint-disable-next-line
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  //time fromat funtion
  const formatTime = (timestamp) => {
    const time = new Date(timestamp * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleString("en-US", options);
  };

  //select drop funtion
  const selectDrop = async (index) => {
    if (index != null && dropData != null) {
      setDropLocation(dropData[index].Location);
      setDropId(dropData[index].DropID);
      setDropStatus(dropData[index].DropStatus);
      setDropName(dropData[index].DropName);
      await makeDropInProgress(dropId);
    }
  };

  const makeDropInProgress = async (dropId) => {
    try {
      if (dropStatus === "Todo") {
        await inProgress(dropId);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Drop Delete
  const selectDropDelete = async (index) => {
    if (dropData !== null || index != null) {
      try {
        setDropDeleteId(sortedDropData[index].DropID);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (dropDeleteId && dropDeleting !== true) {
      setDropDeleting(true);
      const dropDeleteTrigger = async () => {
        try {
          const response = await deleteDropCall(dropDeleteId);
          if (response.success === true) {
            setDeleteDropCurrentStateChange((prevImages) => prevImages + 1);
            dropDeleted === true ? setDropDeleted(false) : setDropDeleted(true);
            setDropDeleting(false);
          } else {
            toast.error("Drop Delete Unsuccesful", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } catch (err) {
          console.error("Drop Delete Function Error", err);
          toast.error("Error Deleting Drop", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setDropDeleting(false);
        }
      };
      dropDeleteTrigger();
    }
    // eslint-disable-next-line
  }, [dropDeleteId]);
  // Mobile - Direct to specific component
  const selectDropMob = async (index) => {
    if (dropData !== null || index != null) {
      try {
        setDropId(sortedDropData[index].DropID);
        setDropLocation(sortedDropData[index].Location);
        setDropStatus(sortedDropData[index].DropStatus);
        setDropName(sortedDropData[index].DropName);
      } catch (err) {
        console.error(err);
      }
    }
  };
  // Navigate to specific drop
  useEffect(() => {
    if (hasMounted) {
      try {
        if (dropId != null) {
          const inProgressTrigger = async () => {
            try {
              await inProgress(dropId);
            } catch (err) {
              console.error(err);
            }
          };

          inProgressTrigger();
          navigate(`/driver/${dropId}`);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setHasMounted(true);
    }
    // eslint-disable-next-line
  }, [dropId, navigate]);
  // const reorder = (newOrder) => {
  //   console.log("reorder function triggered");
  //   const currentOrderMap = dropData.reduce((acc, item) => {
  //     acc[item.DropID] = parseInt(item.DropOrder);
  //     return acc;
  //   }, {});
  //   const newOrderMap = newOrder.reduce((acc, item, index) => {
  //     acc[item.DropID] = index + 1;
  //     return acc;
  //   }, {});
  //   for (let i = 0; i < newOrder.length; i++) {
  //     const currentOrder = currentOrderMap[newOrder[i].DropID];
  //     const newOrderValue = newOrderMap[newOrder[i].DropID];
  //     if (currentOrder !== newOrderValue) {
  //       // Function to call backend
  //       // updateOrderInBackend(currentOrder, newOrderValue);
  //     }
  //   }
  //   setDropData(newOrder);
  // };
  const sortedDropData = [...dropData].sort((a, b) => {
    if (a.DropStatus === "Done" && b.DropStatus !== "Done") return 1;
    if (a.DropStatus !== "Done" && b.DropStatus === "Done") return -1;
    return 0;
  });

  // reorder starting function
  const reorder = (index) => {
    if (index !== undefined) {
      // default scrolling disable
      //document.body.style.overflow = 'hidden';
      //window.addEventListener("touchmove", preventScroll, {passive:false})
      setDraggedItem(index);
    } else {
      console.log("Error set index to dragging items");
    }
  };

  // call enpoint to change order
  const reOrderEnd = async (index) => {
    //document.body.style.overflow='';
    //window.removeEventListener("touchmove", preventScroll);
    if (draggedItem !== undefined &&
      index !== undefined &&
      sortedDropData !== undefined &&
      sortedDropData[draggedItem] !== undefined &&
      sortedDropData[index] !== undefined) {
      if (
        sortedDropData[draggedItem].DropStatus !== "Done" &&
        sortedDropData[index].DropStatus !== "Done"
      ) {
        const currentOrderId = sortedDropData[draggedItem].DropOrder;
        const changeOrderId = sortedDropData[index].DropOrder;
        // changing order
        try {
          if (currentOrderId !== changeOrderId) {
            await dropOrdering(currentOrderId, changeOrderId);
          }
        } catch (err) {
          console.error("reordering function error", err);
        }
      }
    }
  };

  // move card up - arrows
  const moveCardUp = async (index) => {
    if (index !== null && sortedDropData !== null) {
      const currentOrderId = sortedDropData[index].DropOrder;
      const changeOrderId = sortedDropData[index - 1].DropOrder;
      try {
        if (currentOrderId !== null && changeOrderId !== null) {
          const response = await dropOrdering(currentOrderId, changeOrderId);
          if (response) {
            dragItemStateCheck === true
              ? setDragItemStateCheck(false)
              : setDragItemStateCheck(true);
          } else {
            console.error("No response from drop Ordering");
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  // move card down - arrows
  const moveCardDown = async (index) => {
    if (index !== null && sortedDropData !== null) {
      const currentOrderId = sortedDropData[index].DropOrder;
      const changeOrderId = sortedDropData[index + 1].DropOrder;
      try {
        if (currentOrderId !== null && changeOrderId !== null) {
          const response = await dropOrdering(currentOrderId, changeOrderId);
          if (response) {
            dragItemStateCheck === true
              ? setDragItemStateCheck(false)
              : setDragItemStateCheck(true);
          } else {
            console.error("No response from drop Ordering");
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <>
      <div className="current__drop__container__desktop">
        {/* <div className="cdc__details">
          <div className="current__drop__location">
            <h3 className="heading-2">CURRENT DROP</h3>
            <div className="cdl__details">
              <div className="cdl__title">
                <h4 className="heading-2">Location : </h4>
              </div>
              <div>
                <h4 className="heading-3">{dropLocation}</h4>
              </div>
              <div className="cdl__location">
                <p className="text">{dropLocation}</p>
              </div>
              <div>
                <p>{dropName}</p>
              </div>
            </div>
            <button className="button button-1">Open Google Maps</button>
          </div>
          <div className="current__drop__image__container">
            {images.map((image, index) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt={`Preview ${dropName}`}
                className="current-drop-image"
              />
            ))}
            <label className="button button-1" for="input-file">
              Delivery Proof
            </label>
            <input
              type="file"
              accept="image/"
              id="input-file"
              hidden
              onChange={addImages}
            />
          </div>
          <div className="current__drop__comment">
            <input type="text" className="input" placeholder="Comments" />
            <button className="button button-1">Submit Comment</button>
          </div>
        </div> */}
        <div className="cdc__table">
          <table>
            <tr className="heading-3">
              <th></th>
              <th>DROP NAME</th>
              <th>RUNNING DATE</th>
              <th>DELIVARY TIME</th>
              <th>ADDRESS</th>
              <th>DELIVERY STATE</th>
            </tr>
            <tbody>
              {sortedDropData.map((drop, index) => (
                <tr
                  key={index}
                  onClick={() => selectDrop(index)}
                  className="text drop__table__row"
                >
                  <td>{index + 1}</td>
                  <td>{drop.DropName}</td>
                  <td>{formatDate(drop.DateTime)}</td>
                  <td>{formatTime(drop.DateTime)}</td>
                  <td>{drop.Location}</td>
                  <td className="delivary__state__common">{drop.DropStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* loading component */}
      {/* <ClipLoader color={'#000000'} size={150} loading={loadingInProgress}/> */}
      {/* Droppable Component Start */}

      {isDropData === true ? (
        <Reorder.Group axis="y" values={sortedDropData} onReorder={setDropData}>
          {sortedDropData.map((drop, index) => (
            <Reorder.Item
              key={isDropData === true ? drop.DropOrder : index}
              value={drop}
              onDragStart={() => reorder(index)}
              onDragEnd={() => reOrderEnd(index)}
              drag
              dragConstraints={{ top: 0, bottom: 0 }}
              dragElastic={1}
            >
              <div className="current__drop__container">
                <div key={dropData.DropOrder} className="current__drop__card">
                  <div className="common__column current__drop__col1">
                    <div className="current-drop-row">
                      <h2 className="heading-2">{drop.DropName}</h2>
                      <div className="current-drop-hed-btn">
                        <div className="current-drop-move">
                          {index !== 0 ? (
                            <button onClick={() => moveCardUp(index)}>
                              <IoIosArrowDropup size={30} />
                            </button>
                          ) : (
                            <button disabled>
                              <IoIosArrowDropup size={30} />
                            </button>
                          )}

                          {index + 2 > sortedDropData.length ? (
                            <button disabled>
                              <IoIosArrowDropdown size={30} />
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                moveCardDown(index);
                              }}
                            >
                              <IoIosArrowDropdown size={30} xx />
                            </button>
                          )}
                        </div>

                        {drop.DropStatus === "InProgress" ? (
                          <button className="button">
                            <MdDeleteOutline
                              size={20}
                              onClick={() => selectDropDelete(index)}
                            />
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <p
                      className={`text current-drop-status ${
                        drop.DropStatus === "Todo"
                          ? "current-drop-status-todo"
                          : drop.DropStatus === "InProgress"
                          ? "current-drop-status-inprogress"
                          : drop.DropStatus === "Done"
                          ? "current-drop-status-completed"
                          : "current-drop-status"
                      }`}
                    >
                      {drop.DropStatus}
                    </p>
                    <p className="text">{formatDate(drop.DateTime)}</p>
                    <p className="text">{formatTime(drop.DateTime)}</p>
                  </div>
                  <div className="common__column current__drop__col2">
                    <p className="text">{drop.Location}</p>
                    <button
                      className="current-drop-dd-button"
                      onClick={() => selectDropMob(index)}
                    >
                      Drop&nbsp;Details
                    </button>
                  </div>
                </div>
              </div>
            </Reorder.Item>
          ))}
          <ToastContainer />
        </Reorder.Group>
      ) : (
        <NoData />
      )}

      {/* Draggable components End */}
    </>
  );
};
export default CurrentDrop;
