import axios from "axios";
//import { drops } from "../data/drops";

// Add drop function
const addDrop = async (driverID, dropName, location) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/create/drop",
      {
        driver_id: driverID,
        drop_name: dropName,
        location: location,
        drop_type: "Business",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Add drop Error " + error);
    // window.location.href = "/";
  }
};

const driverHistory = async (driverId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/driver/histroy",
      {
        driver_id: driverId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Driver History Error " + err);
  }
};

const currentDrops = async (driverId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/driver/drops",
      {
        driver_id: driverId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Current Drops Error " + err);
    // window.location.href = "/";
  }
};

const sendMessage = async (driverId, message, images) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/new/messages",
      {
        driver_id: driverId,
        message: message,
        image_list: images,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Send Message Error " + err);
    // window.location.href = "/";
  }
};

const getAllDriverMessages = async (driverId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/chat/messages",
      {
        driver_id: driverId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const deliveryComplete = async (dropId, comment, images) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/complete/drop",
      {
        drop_id: dropId,
        comment: comment,
        drop_image: images,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

// Make Drop InProgress
const inProgress = async (dropId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/inprogress/drop",
      {
        drop_id: dropId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const passwordChange = async (contactNo, currentPassword, newPassword) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/change/password",
      {
        contactno: contactNo,
        current_password: currentPassword,
        new_password: newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const sendDropData = async (driverId, dropLocation, dropName, dropType) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/create/drop",
      {
        driver_id: driverId,
        drop_name: dropName,
        location: dropLocation,
        drop_type: dropType,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const reRunDrops = async (driverId, dropDate, dropName, dropLocation) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/drop/search/recreate",
      {
        driver_id: driverId,
        drop_name: dropName,
        location: dropLocation,
        date: dropDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const updateDrop = async (dropId, comment, images) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/drop/update/complete",
      {
        drop_id: dropId,
        comment: comment,
        dropImage: images,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const deleteDropCall = async (dropId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/drop/delete",
      {
        drop_id: dropId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const searchDrop = async (driverId, dropName, dropLocatoion, dropDate) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/search/drop",
      {
        driver_id: driverId,
        drop_name: dropName,
        location: dropLocatoion,
        date: dropDate,
      }
    );
    return response.data;
  } catch (err) {
    console.error("Search Drop Error", err);
  }
};

const dropInformation = async (dropId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/drop/information",
      {
        drop_id: dropId,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const dropOrdering = async (currentOrderId, changeOrderId) => {
  try {
    const response = await axios.post(
      "https://greenland-backend.azurewebsites.net/api/v1.0/access/drop/order",
      {
        current_order: currentOrderId,
        change_order: changeOrderId,
      }
    );
    return response.data;
  } catch (err) {
    console.error("drop order api error", err);
  }
};

export {
  addDrop,
  driverHistory,
  currentDrops,
  sendMessage,
  getAllDriverMessages,
  deliveryComplete,
  inProgress,
  passwordChange,
  sendDropData,
  reRunDrops,
  updateDrop,
  deleteDropCall,
  searchDrop,
  dropInformation,
  dropOrdering,

  // checkBusinessStatus,
};
