
export const ROUTES = {
  LOGIN: "/",
  COMINGSOON: "/coming-Soon",
  // Add other routes here
};

export const ADMINROUTES = {
  DASHBOARD: "/administrator",
  SELECTEDDROP: "/administrator/:id",
  DRIVERMANAGEMENT: "/administrator/driver",
  SETTINGS: "/administrator/settings",
  MESSAGING: "/administrator/contact",
  INDIVIDUALMESSAGING: "/administrator/contact/:id",
  HELP: "/administrator/help",
  DriverTracking: "/administrator/tracking",
  // Add other routes here
};


export const DRIVERROUTES = {
  DASHBOARD: "/driver",
  SPECIFICDROP: "/driver/:id",
  DRIVERHISTORY: "/driver/history",
  MESSAGING: "/driver/contact",
  SETTINGS: "/driver/settings",
  HELP: "/driver/help",
  SIGN: "/driver/sign",
  DROPEDIT: "/driver/history/:id",
  // Add other routes here
};

export const TESTROUTES ={
  LOGINTEST:"/logintest"
}

export const LEGALROUTES ={
  DATACOLLECTION : "/data-collection",
  PRIVACYPOLICY : "/privacy-policy",
  TERMSOFUSE : "/terms-of-use",
  PRIVACYPOLICYMAIN : "/privacy-policy-main"
  // Add other routes here
}