import React, { useState, useEffect } from "react";
import { currentDrops } from "../../../api/apiDriver";
import { useDropContext } from "../../../provider/AddDropProvider";
import { useDeleteContext } from "../../../provider/DeleteDropProvider";
import Loading from "../../common/Loading";


const CurrentState = ({ dropDeleted }) => {
  const driverId = localStorage.getItem("userID");
  const [inProgress, setInProgress] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  // const [todoCount, setTodoCount] = useState(0);
  const { addDrop } = useDropContext();
  const { deleteDrop } = useDeleteContext();
  const [dataLoading, setDataLoading] = useState(true);


  useEffect(() => {
    const dropNumbers = async () => {
      try {
        const data = await currentDrops(driverId);
        if (data === undefined) {
          setDataLoading(false);
        } else {
          if (data.success === true) {
            const dropArray = Array.isArray(data) ? data : [data];
            const arrayLength = data.driverInfo.length;
            console.log(arrayLength);
            let inProgressCount = 0;
            let completedCount = 0;
            // let todoCount = 0;
            setDataLoading(false);

            // loop through all data and count for statuses
            for (let i = 0; i < arrayLength; i++) {
              if (
                arrayLength > 0 &&
                dropArray[0].driverInfo[i].DropStatus === "Todo"
              ) {
                // todoCount++;
              } else if (
                arrayLength > 0 &&
                dropArray[0].driverInfo[i].DropStatus === "InProgress"
              ) {
                inProgressCount++;
              } else {
                completedCount++;
              }
            }
            // after counting setting values
            setInProgress(inProgressCount);
            setCompletedCount(completedCount);
            // setTodoCount(todoCount);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    console.info("Delete Drop", deleteDrop);
    dropNumbers();
    // eslint-disable-next-line
  }, [driverId, addDrop, dropDeleted ]);

  return (
    <div>
      {dataLoading === true ? (
        <Loading />
      ) : (
        <div className="driver__dashboard__numbers">
          {/* <div className="driver_db_number_container">
            <div className="driver__dashboard__numbers__todo">
              <p className="heading-1">{todoCount}</p>
            </div>
            <p className="heading-2">TODO</p>
          </div> */}
          <div className="driver_db_number_container">
            <div className="driver__dashboard__numbers__in__progress">
              <p className="heading-1">{inProgress}</p>
            </div>
            <p className="heading-2">INPROGRESS</p>
          </div>
          <div className="driver_db_number_container">
            <div className="driver__dashboard__numbers__dropped">
              <p className="heading-1">{completedCount}</p>
            </div>
            <p className="heading-2">COMPLETED</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentState;
